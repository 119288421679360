/**
 * Exposes global AppInsights SDK.
 * 
 * Ref.: https://github.com/Microsoft/ApplicationInsights-JS
 */
class AppInsights {
    #ai = window['appInsights'];

    trackPageView(pName: string, pProperties: object = null) : void {
         this.#ai?.trackPageView({ name: pName, properties: pProperties });
    }

    trackEvent(pName: string, pProperties: object = null) : void {
        this.#ai?.trackEvent({ name: pName, properties: pProperties });
    }

    trackPageViewPerformance(pName: string, pUrl: string, pProperties: object = null) : void {
        this.#ai?.trackPageViewPerformance({ name: pName, url: pUrl, properties: pProperties });
    }

    trackException(pError: Error, pProperties: object = null) : void {
        this.#ai?.trackException({ exception: pError, properties: pProperties });
    }

    trackTrace(pMessage: string, pProperties: object = null) : void {
        this.#ai?.trackTrace({ message: pMessage, properties: pProperties });
    }

    trackMetric(pName: string, pProperties: object = null) : void {
        this.#ai?.trackMetric({ name: pName, ...pProperties });
    }

    trackDependencyData(pAbsoluteUrl: string, pResponseCode: number, pMethod: string, pId: string, pProperties: object = null) : void {
        this.#ai?.trackDependencyData({
            absoluteUrl : pAbsoluteUrl,
            responseCode: pResponseCode,
            method: pMethod,
            id: pId,
            properties: pProperties
        });
    }

    startTrackPage(pName: string) : void {
        this.#ai?.startTrackPage(pName);
    }

    stopTrackPage(pName: string, pUrl: string = null, pProperties: object = null) : void {
        this.#ai?.stopTrackPage(pName, pUrl, pProperties);
    }

    startTrackEvent(pName: string) : void {
        this.#ai?.startTrackEvent(pName);
    }

    stopTrackEvent(pName: string, pProperties: object = null) : void {
        this.#ai?.stopTrackEvent(pName, null, pProperties);
    }

    flush(pReason: string = null) : void {
        this.#ai?.flush(pReason);
    }
}
const appInsights = new AppInsights();
export default appInsights;
